import gql from 'graphql-tag';

const FollowingPageListFragment = gql`
	fragment FollowingPageListFragment on PTPaginatedPageList {
		nodes {
			...FollowingPageInfoFragment
		}
		pageInfo {
			hasNextPage
		}
	}
`;

const FollowingPageInfoFragment = gql`
	fragment FollowingPageInfoFragment on PTPage {
		id
		title
		status
		hasChildren
		blank
		hasRestrictions @include(if: $isSuperAdmin)
		hasInheritedRestrictions @include(if: $isSuperAdmin)
		type
		links {
			webui
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
	}
`;

/*
  If you add an optional property to FollowingPageInfoFragment then you need to duplicate this query.
  See https://product-fabric.atlassian.net/browse/SCALE-4250 for more details
*/
/*
  GraphQLPageStatus comes from monolith and PTGraphQLPageStatus comes
  from cc-page-tree and they need to be separate due to a Braid bug:
  https://product-fabric.atlassian.net/browse/SCALE-4686
*/
export const PageTreeFollowingItemsQuery = gql`
	query PageTreeFollowingItemsQuery(
		$spaceKey: String!
		$ptStatuses: [PTGraphQLPageStatus]
		$contentId: ID!
		$paginationLimit: Int
		$isSuperAdmin: Boolean!
	) {
		currentPage: ptpage(
			id: $contentId
			spaceKey: $spaceKey
			pageTree: $paginationLimit
			status: $ptStatuses
			enablePaging: true
		) {
			...FollowingPageInfoFragment
			nearestAncestors {
				pageInfo {
					hasNextPage
				}
				nodes {
					followingSiblings {
						...FollowingPageListFragment
					}
				}
			}
			followingSiblings {
				...FollowingPageListFragment
			}
		}
	}

	${FollowingPageInfoFragment}
	${FollowingPageListFragment}
`;
