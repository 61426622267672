import gql from 'graphql-tag';

import { PageInfoFragment } from './PageInfoFragment.graphql';

const PageListFragment = gql`
	fragment PageListFragment on PTPaginatedPageList {
		nodes {
			...PageInfoFragment
		}
		pageInfo {
			hasNextPage
		}
	}
	${PageInfoFragment}
`;

const LegacyPagesInfoFragment = gql`
	fragment LegacyPagesInfoFragment on Page {
		id
		title
		status
		hasChildren
		blank
		hasRestrictions @include(if: $isSuperAdmin)
		hasInheritedRestrictions @include(if: $isSuperAdmin)
		type
		links {
			webui
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
	}
`;

const ptpageFragment = gql`
	fragment ptpageFragment on PTPage {
		...PageInfoFragment
		nearestAncestors {
			pageInfo {
				hasNextPage
				endCursor
			}
			nodes {
				...PageInfoFragment
				previousSiblings {
					...PageListFragment
				}
				followingSiblings {
					...PageListFragment
				}
			}
		}
		previousSiblings {
			...PageListFragment
		}
		followingSiblings {
			...PageListFragment
		}
		children {
			...PageListFragment
		}
	}
	${PageInfoFragment}
	${PageListFragment}
`;

/*
  If you add an optional property to PageInfoFragment then you need to duplicate this query.
  See https://product-fabric.atlassian.net/browse/SCALE-4250 for more details
*/
/*
  GraphQLPageStatus comes from monolith and PTGraphQLPageStatus comes
  from cc-page-tree and they need to be separate due to a Braid bug:
  https://product-fabric.atlassian.net/browse/SCALE-4686
*/
export const PageTreeQuery = gql`
	query PageTreeQuery(
		$spaceKey: String!
		$statuses: [GraphQLPageStatus]
		$ptStatuses: [PTGraphQLPageStatus]
		$contentId: ID!
		$paginationLimit: Int
		$isSuperAdmin: Boolean!
		$isReplacePagesWithPTPageEnabled: Boolean! = false
	) {
		currentPage: ptpage(
			id: $contentId
			spaceKey: $spaceKey
			pageTree: $paginationLimit
			status: $ptStatuses
			enablePaging: true
		) {
			...ptpageFragment
		}
		ptpage(
			spaceKey: $spaceKey
			pageTree: $paginationLimit
			status: $ptStatuses
			enablePaging: true
		) @include(if: $isReplacePagesWithPTPageEnabled) {
			...PageInfoFragment
			followingSiblings {
				nodes {
					...PageInfoFragment
				}
			}
		}
		rootLevelPages: pages(spaceKey: $spaceKey, status: $statuses)
			@skip(if: $isReplacePagesWithPTPageEnabled) {
			nodes {
				...LegacyPagesInfoFragment
			}
		}
		space(key: $spaceKey) {
			id
			homepage {
				id
			}
			operations {
				operation
				targetType
			}
		}
	}

	${ptpageFragment}
	${PageInfoFragment}
	${LegacyPagesInfoFragment}
`;

export const PageTreeWithSpaceKeyQuery = gql`
	query PageTreeWithSpaceKeyQuery(
		$spaceKey: String!
		$statuses: [GraphQLPageStatus]
		$ptStatuses: [PTGraphQLPageStatus]
		$paginationLimit: Int
		$isSuperAdmin: Boolean!
		$isReplacePagesWithPTPageEnabled: Boolean!
	) {
		space(key: $spaceKey) {
			id
			homepageId
			pageTree(pageTree: $paginationLimit, status: $ptStatuses, enablePaging: true) {
				...ptpageFragment
			}
			operations {
				operation
				targetType
			}
		}
		ptpage(spaceKey: $spaceKey, status: $ptStatuses)
			@include(if: $isReplacePagesWithPTPageEnabled) {
			...PageInfoFragment
			followingSiblings {
				nodes {
					...PageInfoFragment
				}
			}
		}
		rootLevelPages: pages(spaceKey: $spaceKey, status: $statuses)
			@skip(if: $isReplacePagesWithPTPageEnabled) {
			nodes {
				...LegacyPagesInfoFragment
			}
		}
	}
	${ptpageFragment}
	${PageInfoFragment}
	${LegacyPagesInfoFragment}
`;
