import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@atlaskit/primitives';

import { fg } from '@confluence/feature-gating';
import { getLogger } from '@confluence/logger';
import { useEditPageLoadingActions } from '@confluence/load-edit-page/entry-points/EditPageLoadingContext';

const logger = getLogger('LivePagePreloader');

type LivePagePreloaderProps = {
	isLivePage: boolean;
	children: React.ReactNode;
};

export const LivePagePreloader = ({ isLivePage, children }: LivePagePreloaderProps) => {
	const { livePageComponentsPreloader } = useEditPageLoadingActions();
	const [isPreloading, setIsPreloading] = useState<boolean>(false);

	useEffect(() => {
		if (!isPreloading || !isLivePage) return;

		const preloadComponents = () => {
			void livePageComponentsPreloader?.()
				.catch((error: Error) => {
					logger.error`Preloading live page components failed ${error}`;
				})
				.finally(() => {
					setIsPreloading(false);
				});
		};

		void preloadComponents();
	}, [isPreloading, isLivePage, livePageComponentsPreloader]);

	const triggerPreload = useCallback(() => {
		if (isLivePage && !isPreloading) {
			setIsPreloading(true);
		}
	}, [isLivePage, isPreloading]);

	if (!isLivePage || !fg('confluence_perf_preload_livepage_assets')) {
		return children;
	}

	return (
		<Box
			// presentation role here to tell screen readers that this is not interactive
			role="presentation"
			onMouseEnter={triggerPreload}
			onFocus={triggerPreload}
		>
			{children}
		</Box>
	);
};
