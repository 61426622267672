import gql from 'graphql-tag';

import { PageInfoFragment } from './PageInfoFragment.graphql';

export const PageTreeRootLevelPages = gql`
	query PageTreeRootLevelPages(
		$spaceKey: String!
		$statuses: [GraphQLPageStatus]
		$ptStatuses: [PTGraphQLPageStatus]
		$isSuperAdmin: Boolean!
		$paginationLimit: Int
		$isReplacePagesWithPTPageEnabled: Boolean! = false
	) {
		ptpage(spaceKey: $spaceKey, status: $ptStatuses, pageTree: $paginationLimit)
			@include(if: $isReplacePagesWithPTPageEnabled) {
			...PageInfoFragment
			followingSiblings {
				nodes {
					...PageInfoFragment
				}
			}
		}
		rootLevelPages: pages(spaceKey: $spaceKey, status: $statuses, limit: $paginationLimit)
			@skip(if: $isReplacePagesWithPTPageEnabled) {
			nodes {
				id
				hasChildren
				title
				status
				blank
				hasRestrictions @include(if: $isSuperAdmin)
				hasInheritedRestrictions @include(if: $isSuperAdmin)
				type
				properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
					nodes {
						id
						key
						value
						version {
							number
						}
					}
				}
				links {
					webui
				}
			}
		}
		space(key: $spaceKey) {
			id
			homepage {
				id
			}
			operations {
				operation
				targetType
			}
		}
	}
	${PageInfoFragment}
`;
