/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { ApolloError } from 'apollo-client';
import type { FC, ReactNode, MutableRefObject } from 'react';
import React, { Fragment, useEffect, useCallback, memo, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import isEqual from 'lodash/isEqual';
import { useQuery } from '@apollo/react-hooks';

import { SpotlightTarget } from '@atlaskit/onboarding';

import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import {
	ReloadType,
	QuickReloadSubscriber,
} from '@confluence/quick-reload/entry-points/subscription';
import type {
	DragPreview,
	TreeDestinationPosition,
	TreeSourcePosition,
	RenderItem,
	RenderItemParams,
} from '@confluence/tree';
import { LoadableAfterPaint, LoadableLazy } from '@confluence/loadable';
import { ErrorDisplay } from '@confluence/error-boundary';
import { EmptySidebarCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/EmptySidebarCollapsibleSectionLoader';
import { usePageState } from '@confluence/page-context';
import type { PageTreeStateUpdater } from '@confluence/page-tree-refresh-state-container';
import { useIsNav4Enabled } from '@confluence/nav4-enabled';
import { perfMarkStart } from '@confluence/performance';

import { EmojiWrapper, Nav4EmojiWrapper } from './PageTreeItem';
import type { ContentTreeItem } from './data-transformers';
import type { MovePageParams } from './useMovePageHandler';
import type { PageTreeState } from './usePageTreeState';
import { usePageTreeState, PageTreeStateContainer } from './usePageTreeState';
import { PageTreeComponent } from './PageTreeComponent';
import {
	usePageTreeData,
	generateQueryArgs,
	useFetchPages,
	useLoadPagesCallback,
} from './usePageTreeData';
import { useMovePage } from './useMovePage';
import { renderItemEmptyState } from './renderItemEmptyState';
import type { GraphQLPageStatus } from './pageTreeStatuses';
import type { PageTreeQuery as PageTreeQueryResponse } from './queries/__types__/PageTreeQuery';
import type { PageTreeRootLevelPages as PageTreeRootLevelPagesResponse } from './queries/__types__/PageTreeRootLevelPages';

const noop = () => {};

const PageTreeErrorComponentLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageTreeErrorComponent" */ './PageTreeErrorComponent'
			)
		).PageTreeErrorComponent,
});

const FooterCreateButtons = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-FooterCreateButtons" */ '@confluence/create-blank-fabric-page/entry-points/FooterCreateButtons'
			)
		).FooterCreateButtons,
});

const i18n = defineMessages({
	noContentMessage: {
		id: 'page-tree.no-content.message',
		defaultMessage: 'No content in this space',
		description: 'Message when content section is empty',
	},
});

const StopLinkClickSSRInlineScript = () => {
	const nonceValue = window.__SSR_NONCE_VALUE__;
	return (
		<script
			{...(nonceValue !== undefined ? { nonce: nonceValue } : {})}
			dangerouslySetInnerHTML={{
				__html: `(function(){
  const rightChevrons = document.querySelectorAll('[data-testid="chevron-right"]');
  const downChevrons = document.querySelectorAll('[data-testid="chevron-down"]');
  const allChevrons = [...rightChevrons, ...downChevrons];
  for(let i = 0; i <= allChevrons.length; i++){
    allChevrons[i] ? allChevrons[i].onclick= function(e) {
      e.preventDefault();
      e.stopPropagation();
    }: null;
  }
})()`,
			}}
		/>
	);
};

export const PageTreeCoordinatorComponent: FC<PageTreeCoordinatorProps> = memo(
	({
		currentPageId,
		isPeekingFromBlogs,
		isSuperAdmin,
		spaceKey,
		statuses,
		expandSpaceHomepage = false,
		onInitialLoadComplete = noop,
		onLoadMore = noop,
		onDragStart = noop,
		onPageMove = noop,
		setIsPageTreeLoading = noop,
		onMoveInvalid,
		renderItem,
		forceFetchCurrentPage = false,
		forceFetchedCallback,
		highlightPages = [],
		isDraggable,
		syntheticItem,
		detached = false,
		syntheticItemIsDraft,
		getIsDraggingItem,
		setIsDraggingItem,
		dragPreview,
		renderResourcedEmoji,
		setContentTreeSize,
		isMovePageTree,
		pageTreeStateUpdatesContainer,
		isCreateContentRoute = false,
	}) => {
		const prevPropsRef: PrevPropsRef = useRef({
			previousPageId: '',
			previousSpaceKey: '',
			previousHighlightedPages: [],
			previousForceFetchCurrentPage: false,
			loadingUseEffect: false,
		});
		const isMountedRef = useRef(true);
		const [state, actions] = usePageTreeState();
		const {
			pages,
			space,
			rootId,
			isTreeTruncatedPrevious,
			isTreeTruncatedFollowing,
			isPageTreeLoading,
		} = state as PageTreeState;
		const isNav4Enabled = useIsNav4Enabled();
		const renderServerPlaceholder = useRenderServerPlaceholder();
		useEffect(() => {
			setContentTreeSize && setContentTreeSize(Object.keys(pages).length);
		}, [pages, setContentTreeSize]);

		const onLoadMoreFn = useCallback(
			(pageCount: any, direction: any) => {
				if (onLoadMore) {
					onLoadMore(pageCount, space?.id, direction);
				}
			},
			[onLoadMore, space],
		);

		const { loadPreviousPages, loadFollowingPages, reloadChildren, queryChildren } =
			usePageTreeData(spaceKey, isSuperAdmin, statuses, onLoadMoreFn);

		// Note: loading is never true when using refetch, so we are manually updating isPageTreeLoading in handleData
		const { refetch: loadPages } = useFetchPages(
			'1',
			true,
			false,
			detached,
			spaceKey,
			isSuperAdmin,
		);

		const { refetch: forceFetchPages } = useFetchPages(
			'1',
			true,
			true,
			detached,
			spaceKey,
			isSuperAdmin,
		);

		const { refetch: fetchRoot } = useFetchPages(
			undefined,
			true,
			true,
			detached,
			spaceKey,
			isSuperAdmin,
		);
		const updateData = useLoadPagesCallback(
			spaceKey,
			expandSpaceHomepage,
			syntheticItem,
			isPeekingFromBlogs,
			isSuperAdmin,
			statuses,
			syntheticItemIsDraft,
			pageTreeStateUpdatesContainer,
		);

		const handleData = useCallback(
			async (response: any) => {
				const { data, error } = response;
				if (error) {
					// handle errors, ignoring undefined refetch error after unmount
					if (isMountedRef.current) {
						actions.handleLoadingError(error);
					}
					return data;
				} else {
					actions.setIsPageTreeLoading(false);
					updateData(data);
					if (forceFetchCurrentPage && forceFetchedCallback) {
						forceFetchedCallback(state);
					}
					return data;
				}
			},
			[updateData, actions, forceFetchCurrentPage, forceFetchedCallback, state],
		);

		const movePage = useMovePage({
			onMoveInvalid,
			syntheticItem,
			onPageMove,
			queryChildren,
		});

		const firePropCallbacks = useCallback(() => {
			setIsPageTreeLoading(isPageTreeLoading);
		}, [setIsPageTreeLoading, isPageTreeLoading]);

		useEffect(() => {
			// Now that Create Content route lives under the same <MainLayout/> as the other view content routes,
			// the content tree does not get remounted again when going from create -> view content route.
			// Exiting out of this useEffect while on create route will allow the fetching of data with the
			// correct draftId upon redirection.
			if (isCreateContentRoute) {
				return;
			}
			const {
				previousPageId,
				previousSpaceKey,
				previousHighlightedPages,
				previousForceFetchCurrentPage,
			} = prevPropsRef.current;

			const { options } = generateQueryArgs(
				currentPageId,
				true,
				false,
				detached,
				spaceKey,
				isSuperAdmin,
			);
			const fetchPages = currentPageId ? loadPages : fetchRoot;

			const fetchData = async () => {
				const fetchAndHandle = () =>
					forceFetchPages(options.variables)
						.then((response) => handleData(response))
						.catch((error) => handleData({ error }));

				//toDo: this can be massively simplified
				if (
					//have currentPageId
					currentPageId &&
					(forceFetchCurrentPage || previousPageId !== currentPageId)
				) {
					const currentPageInMemory = pages[currentPageId];
					if (forceFetchCurrentPage && !previousForceFetchCurrentPage) {
						return fetchAndHandle();
					} else if (highlightPages.length && !isEqual(previousHighlightedPages, highlightPages)) {
						// Load and/or expand pages we should highlight
						const highlightPage = pages[highlightPages[0]];
						if (!highlightPage) {
							//this happens when unarchiving pages, and MissionControl CI tests require a refresh/needs a loading state to show
							return fetchAndHandle();
						} else if (highlightPage) {
							return actions.expandPage(highlightPage, queryChildren);
						}
					} else if (
						!currentPageInMemory ||
						(spaceKey !== previousSpaceKey && previousSpaceKey !== '') //if we loaded from persistence the previous spacekey will be ""
					) {
						return fetchAndHandle();
					} else {
						if (!window.__SSR_RENDERED__ && previousPageId === '') {
							//only forceFetch on transitions from other views. If local ref isn't set we must be on a transition
							return fetchAndHandle(); //each mount we have to call the server unless we SSR'd
						} else if (
							previousPageId !== currentPageId &&
							currentPageInMemory &&
							(currentPageInMemory.hasChildren || currentPageInMemory.data.type === 'folder')
						) {
							return actions.expandPage(currentPageInMemory, queryChildren);
						}
					}
				}

				if (!currentPageId && spaceKey !== previousSpaceKey) {
					//no currentPageId
					return fetchPages(options.variables)
						.then((response) => {
							return handleData(response);
						})
						.catch((error) => handleData({ error })); //when we change spaces in the movePageDialog or peek from blogs
				}
			};

			if (
				!prevPropsRef.current.loadingUseEffect ||
				(forceFetchCurrentPage && !previousForceFetchCurrentPage)
			) {
				prevPropsRef.current.loadingUseEffect = true;
				fetchData()
					.then((data) => {
						if (data) {
							firePropCallbacks();
						}
						prevPropsRef.current.loadingUseEffect = false;
					})
					.catch((error) => handleData({ error }));
			}
			//we useRef so that we don't trigger another re-render like we would if we used State
			prevPropsRef.current.previousPageId = currentPageId;
			prevPropsRef.current.previousSpaceKey = spaceKey;
			prevPropsRef.current.previousHighlightedPages = highlightPages;
			prevPropsRef.current.previousForceFetchCurrentPage = forceFetchCurrentPage;

			//persist tree between route changes in JS memory
			if (Object.keys(pages).length >= 0) {
				actions.updatePersistedState();
			}
		}, [
			actions,
			currentPageId,
			actions.expandPage,
			forceFetchCurrentPage,
			forceFetchPages,
			highlightPages,
			loadPages,
			fetchRoot,
			pages,
			spaceKey,
			forceFetchedCallback,
			queryChildren,
			firePropCallbacks,
			detached,
			handleData,
			isSuperAdmin,
			isPeekingFromBlogs,
			state,
			syntheticItem,
			syntheticItemIsDraft,
			isCreateContentRoute,
		]);

		// TODO: Delete isMountedRef code after blog tree view has been removed
		// This is needed to prevent undefined refetch error after unmount
		useEffect(() => {
			return () => {
				isMountedRef.current = false;
			};
		}, []);

		const onClick = useCallback(
			//toDo only pass this in if we're in the movePageDialog
			async (item: ContentTreeItem) => {
				if (!syntheticItem) {
					return;
				}

				const sourceId = syntheticItem.id;
				if (item.id === sourceId) {
					return;
				}

				// The reference to `pages` changes fairly frequently.
				// Given that `onClick` only needs `pages` when it is called, we can
				// retrieve the latest `pages` from the store when it is needed.
				// By doing this we can keep the reference to `onClick` more stable
				const { pages: latestPages } = actions.getState();

				const sourceParentId = Object.keys(latestPages).find(
					(id) =>
						latestPages[id]?.data?.hasSyntheticChild ||
						latestPages[id]?.children?.includes(sourceId),
				);

				if (!sourceParentId) {
					return;
				}

				const source = {
					parentId: sourceParentId,
					index: latestPages[sourceParentId]?.children?.indexOf(sourceId),
				};
				const destination = { parentId: item.id };
				source.index !== -1 && movePage(source, destination);
			},
			[syntheticItem, actions, movePage],
		);

		const justExpandedId = useRef<string | null>(null);
		const expandPage = useCallback(
			(page: ContentTreeItem) => {
				perfMarkStart({ subject: 'PageTree', subjectId: 'expandPage' });
				void actions.expandPage(page, queryChildren);
				justExpandedId.current = page.id;
			},
			[actions, queryChildren],
		);

		const isTreeDraggable = useCallback(
			(item: ContentTreeItem) => {
				return isDraggable(item, { space });
			},
			[space, isDraggable],
		);

		const renderPageTreeItem: RenderItem<ContentTreeItem> = useCallback(
			(params) => {
				const spaceHomePageId = space?.homepage?.id;
				const displayEmoji = params?.item?.data?.emoji;
				const contentType = params?.item?.data?.type;
				const isSmartLinkEmbed = contentType === 'embed';
				let emoji: React.ReactNode | undefined;

				if (displayEmoji && !isSmartLinkEmbed) {
					emoji = (
						<LazyEmojiComponentLoader
							emoji={displayEmoji}
							context="pageTree"
							height={14}
							key={displayEmoji}
							wrapper={isNav4Enabled ? Nav4EmojiWrapper : EmojiWrapper}
							renderResourcedEmoji={renderResourcedEmoji}
						/>
					);
				}
				return renderItem({
					...params,
					spaceHomePageId,
					onClick, //only needed at all if we're in the move page dialog
					//@ts-ignore
					updateSinglePage: actions.updateSinglePage,
					// this causes all items in a tree to re-render when a drag starts
					getIsDraggingItem,
					spaceId: space?.id,
					emoji,
					getContentTreeSize: actions.getCurrentTreeSize,
					justExpanded: params.item.id === justExpandedId.current,
				});
			},
			[
				space?.homepage?.id,
				space?.id,
				renderItem,
				onClick,
				actions.updateSinglePage,
				actions.getCurrentTreeSize,
				getIsDraggingItem,
				isNav4Enabled,
				renderResourcedEmoji,
				justExpandedId,
			],
		);

		const onDragStartFn = useCallback(() => {
			onDragStart?.();
		}, [onDragStart]);

		if (state.error) {
			return (
				<Fragment>
					<PageTreeErrorComponentLoader />
					{!state.error.isExpected && <ErrorDisplay error={state.error.apolloError} />}
				</Fragment>
			);
		}

		const showFooterCreateButtons = !detached && spaceKey;

		if (
			!isPageTreeLoading &&
			((space?.homepage?.id &&
				rootId == space?.homepage?.id &&
				pages[rootId].children.length == 0) || // rootId is homepage and homepage has no children. empty state is [VIRTUAL_ROOT_ID, VIRTUAL_LOCAL_ROOT_ID, homepage, and/or any orphan pages]
				(!space?.homepage?.id && Object.keys(pages).length <= 2) || // there is no homepage so tree starts at virtual root. empty state is [VIRTUAL_ROOT_ID, VIRTUAL_LOCAL_ROOT_ID]
				(space?.homepage?.id && rootId != space?.homepage?.id && Object.keys(pages).length < 3)) // there is a homepage, but we show from virtual root (i.e. on archived pages). empty state is [VIRTUAL_ROOT_ID, VIRTUAL_LOCAL_ROOT_ID]
		) {
			// if there are no pages in the space, show tree empty message.
			// there is always 2 synthetic tree items (VIRTUAL_ROOT_ID & VIRTUAL_LOCAL_ROOT_ID)
			const emptyContent = (
				<EmptySidebarCollapsibleSectionLoader>
					<FormattedMessage {...i18n.noContentMessage} />
				</EmptySidebarCollapsibleSectionLoader>
			);

			return showFooterCreateButtons ? (
				<FooterCreateButtons
					spaceKey={spaceKey}
					isContentTreeLoading={isPageTreeLoading}
					emptyContent={emptyContent}
				/>
			) : (
				emptyContent
			);
		}

		return (
			<Fragment>
				<SpotlightTarget name="pagetree">
					<PageTreeComponent
						isDraggable={isTreeDraggable}
						isTreeTruncatedPrevious={isTreeTruncatedPrevious}
						isTreeTruncatedFollowing={isTreeTruncatedFollowing}
						loadPreviousPages={loadPreviousPages}
						loadFollowingPages={loadFollowingPages}
						//@ts-ignore
						onCollapse={actions.collapsePage}
						onDragStart={onDragStartFn}
						onDragEnd={movePage}
						onExpand={expandPage}
						onInitialLoadComplete={onInitialLoadComplete}
						tree={{
							rootId: rootId!,
							items: pages,
						}}
						loading={isPageTreeLoading}
						refactor
						renderItem={renderPageTreeItem}
						{...(!isMovePageTree && { renderItemEmptyState })}
						setIsDraggingItem={setIsDraggingItem}
						dragPreview={dragPreview}
					/>
				</SpotlightTarget>
				{showFooterCreateButtons && (
					<FooterCreateButtons spaceKey={spaceKey} isContentTreeLoading={isPageTreeLoading} />
				)}
				<QuickReloadSubscriber
					name="pagetree"
					types={[ReloadType.content]}
					reload={reloadChildren as any}
				/>
				{renderServerPlaceholder && <StopLinkClickSSRInlineScript />}
			</Fragment>
		);
	},
);

export const PageTreeCoordinator: FC<PageTreeCoordinatorProps> = ({ ...props }) => {
	const { query, options } = generateQueryArgs(
		props.currentPageId,
		true,
		false,
		false,
		props.spaceKey,
		props.isSuperAdmin,
	);

	const { data, error } = useQuery<PageTreeQueryResponse | PageTreeRootLevelPagesResponse>(query, {
		variables: options.variables,
		fetchPolicy: 'cache-only',
	}); //cacheOnly will load cached Data

	const [{ contentId: selectedPageId }] = usePageState();
	const derrivedCurrentPage = props.detached ? props.currentPageId : selectedPageId;

	if (error) {
		return <PageTreeErrorComponentLoader />;
	}
	return (
		<PageTreeStateContainer
			currentPageId={derrivedCurrentPage}
			// @ts-ignore
			highlightPages={props.highlightPages}
			initialData={data}
			spaceKey={props.spaceKey}
			syntheticItem={props.syntheticItem}
			onExpand={props.onExpand}
			onCollapse={props.onCollapse}
			onLoadError={props.onLoadError}
			syntheticItemIsDraft={props.syntheticItemIsDraft}
			isPeekingFromBlogs={props.isPeekingFromBlogs}
			detached={props.detached}
		>
			<PageTreeCoordinatorComponent
				currentPageId={props.currentPageId}
				isPeekingFromBlogs={props.isPeekingFromBlogs}
				isSuperAdmin={props.isSuperAdmin}
				spaceKey={props.spaceKey}
				statuses={props.statuses}
				setIsPageTreeLoading={props.setIsPageTreeLoading}
				expandSpaceHomepage={props.expandSpaceHomepage}
				onInitialLoadComplete={props.onInitialLoadComplete}
				onLoadMore={props.onLoadMore}
				onDragStart={props.onDragStart}
				onPageMove={props.onPageMove}
				onMoveInvalid={props.onMoveInvalid}
				renderItem={props.renderItem}
				isDraggable={props.isDraggable}
				syntheticItem={props.syntheticItem}
				detached={props.detached}
				syntheticItemIsDraft={props.syntheticItemIsDraft}
				forceFetchCurrentPage={props.forceFetchCurrentPage}
				forceFetchedCallback={props.forceFetchedCallback}
				highlightPages={props.highlightPages}
				getIsDraggingItem={props.getIsDraggingItem}
				setIsDraggingItem={props.setIsDraggingItem}
				setOpenQuickActionsId={props.setOpenQuickActionsId}
				dragPreview={props.dragPreview}
				renderResourcedEmoji={props.renderResourcedEmoji}
				setContentTreeSize={props.setContentTreeSize}
				selectedPageId={selectedPageId}
				isMovePageTree={props.isMovePageTree}
				pageTreeStateUpdatesContainer={props.pageTreeStateUpdatesContainer}
				isCreateContentRoute={props.isCreateContentRoute}
			/>
		</PageTreeStateContainer>
	);
};

export type PageTreeCoordinatorProps = {
	currentPageId?: string;
	isPeekingFromBlogs?: boolean;
	isSuperAdmin?: boolean;
	spaceKey?: string;
	forceFetchedCallback?: (data?: any) => void;
	setIsPageTreeLoading?: (isLoading: boolean) => void;
	statuses?: GraphQLPageStatus[];

	/**
	 * Controls whether space homepage should be expanded once loaded.
	 * Useful if `currentPageId` is an orphan, or if using `syntheticItem`.
	 */
	expandSpaceHomepage?: boolean;

	onInitialLoadComplete?(): void;
	onLoadMore?(count?, spaceId?, direction?): void;
	onDragStart?(): void;
	onPageMove(
		moveParams: MovePageParams,
		additionalParams: {
			contentStatus: string | null;
			movedPageTitle: string;
			spaceId?: string | null;
			sourceParent: ContentTreeItem;
			source: TreeSourcePosition;
			destination: TreeDestinationPosition;
			revertMove(): MovePageParams;
			instructionType?: string | null;
		},
	): void;
	onMoveInvalid?(options: {
		reason: 'TREE_TOO_LARGE' | 'INVALID_MOVE_LOCATION' | 'INVALID_MOVE_LOCATION_DRAFT';
	}): void;
	onLoadError?(apolloError: ApolloError, options?: { isExpected?: boolean }): void;

	onCollapse?(page: ContentTreeItem, options: { spaceId?: string | null }): void;
	onExpand?(page: ContentTreeItem, options: { spaceId?: string | null }): void;
	renderItem(
		item: RenderItemParams<ContentTreeItem> & {
			spaceHomePageId: string | undefined;
			onClick: (page: ContentTreeItem) => void;
			updateSinglePage: (id: string, data: object) => void;
			getIsDraggingItem?: () => boolean;
			spaceId?: string | null;
			emoji?: ReactNode;
			getContentTreeSize: () => number;
		},
	): ReactNode;
	renderResourcedEmoji?: boolean;

	// NOTE: If passing in both a valid currentPageId and highlightPages,
	// currentPageId takes preference in terms of how we load (and focus) the
	// page tree, and only those highlightPages that are resulting /
	// present in the page tree's state will be visually highlighted.

	// Similarly, if passing in >1 highlightPages, this component will reload and query
	// for the first ID in the array (as all page tree queries accept only a
	// single content ID focal point), but will highlight all "highlightPages"
	// that are loaded into state as a result (e.g. root pages or parents/siblings
	// of the focal highlighted page).
	highlightPages?: string[];

	// forces us to requery network for information on the current page
	forceFetchCurrentPage?: boolean;
	isDraggable(
		item: ContentTreeItem,
		options: {
			space?: {
				id: string;
				operations: Array<{ targetType: string; operation: string }> | null;
			} | null;
		},
	): boolean;

	/**
	 * This item is a "synthetic" one in a sense that it might not belong
	 * to the page tree that is currently being displayed.
	 * If provided, it will be either displayed as the first item on the upper-most
	 * parent of the tree (if it did not belong to the original tree), or override the
	 * original tree item (if it did belong to the tree).
	 *
	 * IMPORTANT NOTE: performing a drag of another item to appear right after this synthetic one
	 * will most likely produce undesirable and unexpected results.
	 *
	 * Valid use-cases include:
	 *  - (existing) moving a page from another space via drag-n-drop;
	 *  - (hypothetical) displaying a duplicate item on the tree for, like, discovery purposes
	 *  - (hypothetical) displaying a truly synthetic item that is non-homogenous to other TreeItem entries (i.e. pages)
	 *  - maybe others 😁
	 *
	 * ## 🛑 Current drawbacks 🐞
	 * Using `currentPageId` in conjunction with `syntheticItem` might produce undesirable UX on large trees.
	 * For example, if you have 500 pages in a tree and the current page is at position 300, then the first 50 pages won't
	 * actually be returned by the API. With a `syntheticItem` the tree would take approximately the following shape:
	 *
	 * ```
	 * <Load more button>
	 * synthetic item
	 *    pages 50-299
	 *    current page (300)
	 *    pages 301-500
	 * <Load more button>
	 * ```
	 */
	syntheticItem?: ContentTreeItem;
	/*
	 * Prop that signifies if we are outside normal pageLayout like the move page dialog. Tells controller to ignore all chaching
	 */
	detached?: boolean;
	syntheticItemIsDraft?: boolean;
	getIsDraggingItem?: () => boolean;
	setIsDraggingItem?: (state: boolean) => void;
	setOpenQuickActionsId?: (id: string | null) => void;
	dragPreview?: DragPreview<ContentTreeItem>;
	setContentTreeSize?: (size: number) => void;
	/**
	 * The id of the content that the user is viewing the route of. This is passed into useLoadPagesCallback when folders is enabled.
	 * The page tree filters out unpublished drafts except the one that was just created. We want to know if an unpublished draft is
	 * the selected page to avoid filtering it out after folder creation, as folders do not redirect to a create content route.
	 */
	selectedPageId?: string;
	isMovePageTree?: boolean;
	pageTreeStateUpdatesContainer?: PageTreeStateUpdater;
	isCreateContentRoute?: boolean;
};

type PrevPropsRef = MutableRefObject<{
	previousPageId: PageTreeCoordinatorProps['currentPageId'];
	previousSpaceKey: PageTreeCoordinatorProps['spaceKey'];
	previousHighlightedPages: PageTreeCoordinatorProps['highlightPages'];
	previousForceFetchCurrentPage: boolean;
	loadingUseEffect: boolean;
}>;
