import gql from 'graphql-tag';

export const PageInfoFragment = gql`
	fragment PageInfoFragment on PTPage {
		id
		title
		status
		createdDate(format: MILLIS) {
			value
		}
		hasChildren
		blank
		hasRestrictions @include(if: $isSuperAdmin)
		hasInheritedRestrictions @include(if: $isSuperAdmin)
		type
		subType
		links {
			webui
		}
		properties(keys: ["emoji-title-published", "emoji-title-draft", "editor"]) {
			nodes {
				id
				key
				value
				version {
					number
				}
			}
		}
	}
`;
