import produce from 'immer';
import unionBy from 'lodash/unionBy';

import type { ItemId, TreeObjectItems } from '@confluence/tree';
import { fg } from '@confluence/feature-gating';

import type { ContentTreeItem } from './data-transformers';
import { VIRTUAL_ROOT_ID, VIRTUAL_LOCAL_ROOT_ID } from './virtualRootIds';

export const getPages = <T = any>(
	parent: { nodes: T[] | null | undefined } | null | undefined,
): T[] => {
	return parent?.nodes?.length ? parent.nodes : [];
};

export const getIds = (pages): ItemId[] => pages.map((page) => page.id);

/**
 * Determines if a virtual root is necessary to render orphans.
 *
 * @param {object} data
 * @returns {String} rootId
 */
export const getRootId = (pages, currentPageId, spaceHomePageId) => {
	if (!spaceHomePageId || !pages || !currentPageId) {
		return VIRTUAL_ROOT_ID;
	}

	const currentPage = pages[currentPageId];

	if (!currentPage) {
		return VIRTUAL_ROOT_ID;
	}

	switch (currentPageId) {
		case spaceHomePageId:
			return spaceHomePageId;
		case VIRTUAL_LOCAL_ROOT_ID:
			return VIRTUAL_LOCAL_ROOT_ID;
		default:
			return getRootId(pages, currentPage.parentId, spaceHomePageId);
	}
};

/**
 * Determines if this page's children are fresh from the server and should
 * be rendered.
 *
 * This page might have "additional children" if some other page was re-parented to this one
 * and the latest children haven't been fetched from the server yet.
 *
 * @param {object} page
 * @returns {boolean}
 */
export const childrenAreLatest = (page) =>
	(!page.hasChildren || (Boolean(page.children) && page.children.length > 0)) &&
	!page.hasAdditionalChildren;

/**
 * Merges newPages into allPages preserving children if they weren't loaded
 * as part of newPages, unless explicitly removed in newPages.
 *
 * @param {object} allPages
 * @param {object} newPages
 * @returns {object}
 */
export const mergePages = (allPages, newPages): TreeObjectItems<ContentTreeItem> =>
	produce<TreeObjectItems<ContentTreeItem>>(allPages, (mergedPages) => {
		for (const pageId in newPages) {
			const oldPage = allPages[pageId];
			const newPage = newPages[pageId];

			if (oldPage) {
				const children = childrenAreLatest(newPage) ? newPage.children : oldPage.children;
				const parentId =
					//ensures when paginating we don't accidentally leave behind a page incorrectly pointing to
					// the Virtual root causing the space overview to display in the tree
					(oldPage.parentId === VIRTUAL_LOCAL_ROOT_ID && newPage?.parentId) || oldPage.parentId;
				mergedPages[pageId] = {
					...newPage,
					parentId,
					children,
				};
			} else {
				mergedPages[pageId] = newPage;
			}
		}

		return mergedPages;
	});

export const mergeChildren = (allChildren, newChildren) => ({
	nodes: [...allChildren.nodes, ...newChildren.nodes],
});

/**
 * Edge case:
 * 	- If page is too deep within tree to reach ancestor siblings, we can't detect whether
 * 		there are following nodes.
 * @param {object} page
 * @returns {boolean | null}
 */
export const isFollowingNodeHidden = (page) => {
	if (!page) {
		return false;
	}

	const { followingSiblings, nearestAncestors } = page;

	const pagesDontExistBelowForKnownAncestors = !(
		followingSiblings?.pageInfo?.hasNextPage ||
		nearestAncestors.nodes.some((ancestor) => ancestor.followingSiblings?.pageInfo?.hasNextPage)
	);

	const moreAncestorsExist = nearestAncestors?.pageInfo?.hasNextPage;

	return pagesDontExistBelowForKnownAncestors && moreAncestorsExist;
};

export const isPageTreeTruncatedPrevious = (page) => {
	if (!page) {
		return false;
	}

	const { previousSiblings, nearestAncestors } = page;

	return (
		nearestAncestors?.pageInfo?.hasNextPage ||
		previousSiblings?.pageInfo?.hasNextPage ||
		nearestAncestors.nodes.some((ancestor) => ancestor.previousSiblings?.pageInfo?.hasNextPage)
	);
};

export const isPageTreeTruncatedFollowing = (page) => {
	if (!page) {
		return false;
	}

	const { followingSiblings, children, nearestAncestors } = page;

	if (isFollowingNodeHidden(page) && fg('confluence_page_tree_show_more_pages')) {
		return true;
	}

	return (
		followingSiblings?.pageInfo?.hasNextPage ||
		children?.pageInfo?.hasNextPage || // we only request children on first load
		nearestAncestors.nodes.some((ancestor) => ancestor.followingSiblings?.pageInfo?.hasNextPage)
	);
};

export const getAncestryIds = (pageId, allPages, idSet) => {
	if (allPages[pageId]) {
		const { parentId } = allPages[pageId];
		if (parentId) {
			idSet.add(parentId);
			return getAncestryIds(parentId, allPages, idSet);
		}
	}
	return idSet;
};

const emptyField = () => ({
	nodes: [],
	pageInfo: { hasNextPage: false },
});

const emptyFieldWithNextPage = () => ({
	nodes: [],
	pageInfo: { hasNextPage: true },
});

/*
 * if ancestorIndex is -2, and we need to shift UP one level and utilize children
 * if ancestorIndex is -1, no changes are needed
 * if ancestorIndex is 0 or higher, we need to shift DOWN n levels, where n = ancestorIndex + 1.
 */
const shiftedSiblings = (
	currentPage,
	ancestorIndex,
	fieldName: 'previousSiblings' | 'followingSiblings',
) => {
	const allSiblings = [
		currentPage[fieldName],
		...currentPage.nearestAncestors.nodes.map((a) => a[fieldName]),
	];
	return {
		children: ancestorIndex < -1 ? allSiblings.shift() : emptyField(),
		siblings: ancestorIndex < 0 ? allSiblings.shift() : emptyField(),
		ancestorSiblings: [
			...Array.from({ length: Math.max(0, ancestorIndex) }).map(emptyField),
			...allSiblings,
		],
	};
};

const removeSiblingsFromAncestors = (currentPage) => {
	return {
		nodes: [
			...currentPage.nearestAncestors.nodes.map((ancestor) => {
				const { previousSiblings, followingSiblings, ...otherFields } = ancestor;

				// Show more above bug - if currentpage has no following siblings when merging
				// and in feature gate, we set hasNextPage optimistically to true
				return {
					previousSiblings: emptyField(),
					followingSiblings:
						!followingSiblings && fg('confluence_page_tree_show_more_pages')
							? emptyFieldWithNextPage()
							: emptyField(),
					...otherFields,
				};
			}),
		],
		pageInfo: currentPage.nearestAncestors.pageInfo,
	};
};

/**
 * merges the currentPage into allPages if they do not already
 * exist in allPages.
 *
 * @param currentPage
 * @param allPages
 * @param ancestorIndex
 * @returns {object}
 */
export const mergeFollowingPages = (currentPage, allPages, ancestorIndex) => {
	if (!allPages) {
		return currentPage;
	}
	return produce(allPages, (mergedPages) => {
		const {
			followingSiblings: mpFollowingSiblings,
			nearestAncestors: mpNearestAncestors,
			children: mpChildren,
		} = mergedPages;

		const { children, siblings, ancestorSiblings } = shiftedSiblings(
			currentPage,
			ancestorIndex,
			'followingSiblings',
		);

		// followingSiblings
		mpFollowingSiblings.nodes = unionBy(mpFollowingSiblings?.nodes, siblings?.nodes, 'id');
		mpFollowingSiblings.pageInfo.hasNextPage &&= siblings?.pageInfo?.hasNextPage;

		// children
		mpChildren.nodes = unionBy(mpChildren?.nodes, children?.nodes, 'id');
		mpChildren.pageInfo.hasNextPage &&= children?.pageInfo?.hasNextPage;

		// nearestAncestors.followingSiblings
		ancestorSiblings.forEach((ancestorSiblingsAtLevel, i) => {
			const ancestor = mpNearestAncestors?.nodes?.[i];
			if (ancestor) {
				ancestor.followingSiblings.nodes = unionBy(
					ancestor.followingSiblings?.nodes,
					ancestorSiblingsAtLevel?.nodes,
					'id',
				);
				ancestor.followingSiblings.pageInfo.hasNextPage &&=
					ancestorSiblingsAtLevel.pageInfo?.hasNextPage;
			}
		});

		return mergedPages;
	});
};

/**
 * merges the currentPage into allPages if they do not already
 * exist in allPages.
 *
 * @param currentPage
 * @param allPages
 * @param ancestorIndex
 * @returns {object}
 */
export const mergePreviousPages = (currentPage, allPages, ancestorIndex) => {
	if (!allPages) {
		return currentPage;
	}
	return produce(allPages, (mergedPages) => {
		const { previousSiblings: mpPreviousSiblings, nearestAncestors: mpNearestAncestors } =
			mergedPages;

		const { siblings, ancestorSiblings } = shiftedSiblings(
			currentPage,
			ancestorIndex,
			'previousSiblings',
		);

		// previousSiblings
		mpPreviousSiblings.nodes = unionBy(mpPreviousSiblings.nodes, siblings.nodes, 'id');
		mpPreviousSiblings.pageInfo.hasNextPage &&= siblings.pageInfo.hasNextPage;

		// nearestAncestors
		const ancestors = removeSiblingsFromAncestors(currentPage);
		mpNearestAncestors.nodes = unionBy(mpNearestAncestors.nodes, ancestors.nodes, 'id');
		mpNearestAncestors.pageInfo.hasNextPage &&= ancestors.pageInfo.hasNextPage;

		// nearestAncestors.previousSiblings
		ancestorSiblings.forEach((ancestorSiblingsAtLevel, i) => {
			if (i < mpNearestAncestors.nodes.length) {
				const ancestor = mpNearestAncestors.nodes[i];
				ancestor.previousSiblings.nodes = unionBy(
					ancestor.previousSiblings.nodes,
					ancestorSiblingsAtLevel.nodes,
					'id',
				);
				ancestor.previousSiblings.pageInfo.hasNextPage &&=
					ancestorSiblingsAtLevel.pageInfo.hasNextPage;
			}
		});

		return mergedPages;
	});
};

/**
 * Determines the necessary cursors to requery the next set of data.
 * If the cursor is null the most recent cursor is retained
 */
export const getDirectionalCursors = (
	currentPage,
	previousCursors,
	direction?: 'previous' | 'following',
) => {
	if (!currentPage) {
		return previousCursors;
	}
	const previous =
		direction === 'following'
			? previousCursors.previous
			: getPreviousCursor(currentPage, previousCursors.previous);
	const following =
		direction === 'previous'
			? previousCursors.following
			: getFollowingCursor(currentPage, previousCursors.following);
	return { previous, following };
};

const getPreviousCursor = (currentPage, lastCursor) => {
	// if current page has ancestors, get deepest ancestor, else current node
	// if page has previous siblings, then previousmost sibling, otherwise current node
	const ancestorIndex = currentPage.nearestAncestors.nodes.length - 1;
	const adjustedIndex = ancestorIndex + (lastCursor?.ancestorIndex ?? -1) + 1;
	const furthestAncestor = currentPage.nearestAncestors.nodes[ancestorIndex] ?? currentPage;
	const siblingIndex = furthestAncestor.previousSiblings.nodes.length - 1;
	const firstPage = furthestAncestor.previousSiblings.nodes[siblingIndex] ?? furthestAncestor;
	return { id: firstPage.id, ancestorIndex: adjustedIndex };
};

const getFollowingCursor = (currentPage, lastCursor) => {
	// find deepest ancestor's deepest following sibling
	// if none, then last following sibling
	// if no sibling, then last child
	// if no children, then current page
	//findLastIndex is not supported in testing or SSR as it's not supported in node until 18.0.0 and we're on 16.20.0
	if (!Array.prototype.findLastIndex) {
		Array.prototype.findLastIndex = function (callback, thisArg) {
			for (let i = this.length - 1; i >= 0; i--) {
				if (callback.call(thisArg, this[i], i, this)) return i;
			}
			return -1;
		};
	}
	const ancestorIndex = currentPage.nearestAncestors.nodes.findLastIndex(
		(a) => a.followingSiblings.nodes.length > 0,
	);

	const adjustedIndex = ancestorIndex + (lastCursor?.ancestorIndex ?? -1) + 1;
	if (ancestorIndex >= 0) {
		const siblings = currentPage.nearestAncestors.nodes[ancestorIndex].followingSiblings.nodes;
		const siblingIndex = siblings.length - 1;
		const sibling = siblings[siblingIndex];
		return { id: sibling.id, ancestorIndex: adjustedIndex };
	}
	const siblingIndex = currentPage.followingSiblings.nodes.length - 1;
	if (siblingIndex >= 0) {
		const sibling = currentPage.followingSiblings.nodes[siblingIndex];
		return { id: sibling.id, ancestorIndex: adjustedIndex };
	}
	const childIndex = currentPage.children.nodes.length - 1;
	if (childIndex >= 0) {
		const child = currentPage.children.nodes[childIndex];
		return { id: child.id, ancestorIndex: adjustedIndex - 1 };
	}
	return { id: currentPage.id, ancestorIndex: adjustedIndex };
};
