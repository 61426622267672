import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import AkChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import DragHandleVerticalIcon from '@atlaskit/icon/core/drag-handle-vertical';
import DocumentsIcon from '@atlaskit/icon/core/pages';
import { token } from '@atlaskit/tokens';
import NodeIcon from '@atlaskit/icon/core/node';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import type { ContentTreeItem } from './data-transformers';
import { PageTreeItemContent } from './PageTreeItem';

const i18n = defineMessages({
	childrenCountText: {
		id: 'page-tree.children-count-plural',
		defaultMessage: `{count, plural,
      one { includes # child }
      other { includes # children }
    }`,
		description:
			'Used for contextual information. Tells the user how many children does a page have. Use ICU format for pluralization.',
	},
	untitledDraftText: {
		id: 'page-tree.move-dialog.untitled-draft',
		defaultMessage: 'Untitled',
		description: 'A placeholder for drafts that have no title',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragPreviewContainer = styled.div<{
	isMovePageDialog?: boolean;
}>({
	'--grid': '8px',
	padding: 'var(--grid) calc(var(--grid) * 2)',
	display: 'flex',
	alignItems: 'center',
	borderRadius: 3,
	boxSizing: 'border-box', // we want the width to apply to the border box, and not the content box (default)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props) => (props.isMovePageDialog ? '450px' : '210px')}`,
	background: token('elevation.surface.raised'),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BeforeIcon = styled.span<{ isNav4Enabled?: boolean }>({
	display: 'flex',
	width: '24px',
	height: '24px',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginRight: ({ isNav4Enabled }) => (isNav4Enabled ? token('space.0') : token('space.100')),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	flex: '1 1 auto',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildrenCountContainer = styled.span({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
});

export type DragPreviewProps = {
	item: ContentTreeItem;
	type?: 'move-page-tree';
	childPagesCount?: number;
};

export const DragPreview: FC<DragPreviewProps> = ({ item, type, childPagesCount }) => {
	const intl = useIntl();
	const draftText = intl.formatMessage(i18n.untitledDraftText);
	const isMovePageDialog = type === 'move-page-tree';
	const isNav4Enabled = useIsNav4Enabled();
	const isLive = !!(item?.data?.subType === 'live');

	let beforeIcon = <NodeIcon label="" />;
	if (isMovePageDialog) {
		beforeIcon = <DragHandleVerticalIcon label="" color={token('color.icon')} />;
	} else if (item.hasChildren || item.data.type === 'folder') {
		beforeIcon = <AkChevronRightIcon label="" />;
	}

	return (
		<DragPreviewContainer isMovePageDialog={isMovePageDialog}>
			<BeforeIcon isNav4Enabled={isNav4Enabled}>{beforeIcon}</BeforeIcon>
			<TitleWrapper>
				<PageTreeItemContent
					id={item.id.toString()}
					displayHomePageIcon={false}
					displayEmoji={item.data.emoji}
					displayTitle={item.data.title || draftText}
					isDraft={item.data.status === 'DRAFT'}
					contentType={!item.data.emoji ? item.data.type : undefined}
					draggableState="idle"
					isSelected={false}
					isLive={isLive}
				/>
			</TitleWrapper>
			{!!childPagesCount && childPagesCount > 0 && (
				<ChildrenCountContainer>
					<DocumentsIcon label="" color={token('color.icon')} />{' '}
					<FormattedMessage {...i18n.childrenCountText} values={{ count: childPagesCount }} />
				</ChildrenCountContainer>
			)}
		</DragPreviewContainer>
	);
};
